import { api } from './common'

export const getQuizList = (token) =>
  api({
    method: 'GET',
    path: '/builder/quiz',
    token,
  })

export const createQuiz = (quiz, token) =>
  api({
    method: 'POST',
    path: '/builder/quiz',
    token,
    body: quiz,
  })

export const cloneQuiz = (quizId, quiz, options, token) =>
  api({
    method: 'POST',
    path: `/builder/quiz/clone/${quizId}`,
    token,
    body: {
      quiz,
      options,
    },
  })

export const unlockQuiz = (quizId, quiz, token) =>
  api({
    method: 'POST',
    path: `/builder/quiz/unlock/${quizId}`,
    token,
    body: {
      quiz,
    },
  })

export const createQuizOnly = (quiz, token) =>
  api({
    method: 'POST',
    path: '/builder/quiz/simple',
    token,
    body: quiz,
  })

export const saveQuiz = (quiz, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quiz.id}`,
    token,
    body: quiz,
  })

export const saveQuizOnly = (quiz, section = '', token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/simple/${quiz.id}/${section}`,
    token,
    body: quiz,
  })

export const patchQuiz = (quizId, changes, token) =>
  api({
    method: 'PATCH',
    path: `/builder/quiz/simple/${quizId}`,
    token,
    body: changes,
  })

export const getQuiz = (id, token) =>
  api({
    method: 'GET',
    path: `/builder/quiz/${id}`,
    token,
  })

export const getPublishedQuizByVersion = ({ id, version, token }) =>
  api({
    method: 'GET',
    path: `/builder/quiz/${id}/version/${version}`,
    token,
  })

export const getQuizPreview = (id) =>
  api({
    method: 'GET',
    path: `/builder/quiz/preview/${id}`,
  })

export const delQuiz = (id, token) =>
  api({
    method: 'DELETE',
    path: `/builder/quiz/${id}`,
    token,
  })

export const publishQuiz = (id, collectionId, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/publish/${id}`,
    body: {
      collectionId,
    },
    token,
  })

export const updateWeights = (quizId, weights, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/weights`,
    token,
    body: weights,
  })

export const changeQuizOwner = (quizId, newOwnerEmail, token) =>
  api({
    method: 'PATCH',
    path: `/builder/quiz/${quizId}/owner`,
    token,
    body: {
      email: newOwnerEmail,
    },
  })

export const getQuizShareInfo = (quizId, token) =>
  api({
    method: 'GET',
    path: `/builder/quiz/${quizId}/share`,
    token,
  })

export const shareQuiz = (quizId, data, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/share`,
    token,
    body: data,
  })

export const switchQuiz = (quizId, withQuizId, token) =>
  api({
    method: 'PATCH',
    path: `/builder/quiz/${quizId}/switch`,
    token,
    body: { withQuizId },
  })

export const updateStyles = (quizId, styles, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/styles`,
    token,
    body: styles,
  })

export const setTags = (quizId, tags, token) =>
  api({
    method: 'PUT',
    path: `/builder/quiz/${quizId}/tags`,
    body: {
      tags,
    },
    token,
  })
export const removeTag = (quizIds, tag, token) =>
  api({
    method: 'DELETE',
    path: `/builder/quiz/tag`,
    body: {
      quizIds,
      tag,
    },
    token,
  })
let getVersionsPromise
export const getVersions = () => {
  if (!getVersionsPromise) {
    getVersionsPromise = api({
      method: 'GET',
      path: `/instance-bundle/versions`,
    })
  }
  return getVersionsPromise
}
