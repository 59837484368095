import quizActions from './quiz/quiz'
import lockingActions from './quiz/locking'
import sharingActions from './quiz/sharing'
import graphActions from './quiz/graph'
import questionActions from './quiz/question'
import interstitialActions from './quiz/interstitial'
import resultActions from './quiz/result'
import channelActions from './quiz/channel'
import revisionActions from './quiz/revision'
import questionTemplate from './quiz/questionTemplate'

export default function ({ api, quiz, revisions }, history, state) {
  const quizActionList = quizActions(quiz, state, api)
  const graphActionList = graphActions(quiz, quizActionList.autosave)

  return {
    ...quiz,
    ...quizActionList,
    ...lockingActions(quiz, state, api),
    ...sharingActions(quiz, state, api),
    ...graphActionList,
    ...questionActions(quiz, state, api, graphActionList.updateGraphQuestionPosition),
    ...interstitialActions(quiz, state, api),
    ...resultActions(quiz, state, api),
    ...channelActions(quiz, state),
    ...revisionActions(quiz, state, api, revisions),
    ...questionTemplate(quiz, state, api),
  }
}
