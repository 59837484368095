import * as templates from 'src/services/api/templates'

export default function (quiz, state, api, revisions) {
  const getQuestionTemplates = () => {
    api.loading({ name: 'quiz/question-templates' })

    return templates.getTemplatesList( state.session.token)
      .then((res) => {
        if (res.error) {
          throw res.error
        }
        return res
      })
      .catch((error) => {
        api.error({ name: 'quiz/question-templates', error })
        throw error
      })
      .finally(() => {
        api.finish({ name: 'quiz/question-templates' })
      })
  }

  return {
    getQuestionTemplates,
  }
}
