/* eslint react/prop-types: "off" */

import React, { lazy } from 'react'
import { Redirect } from 'react-router-dom'

import { userRoles } from 'src/constants'
import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import EmptyLayout from './layouts/Empty'
import DashboardLayout from './layouts/Dashboard'

import AuthGuard from 'src/components/AuthGuard'
import WebSocketProvider from 'src/components/WebSocketProvider'

const UserManagementList = lazy(() => import('src/views/UserManagementList'))
const UserSettings = lazy(() => import('src/views/Settings'))
const QuizManagementList = lazy(() => import('src/views/QuizManagementList'))
const QuizForm = lazy(() => import('src/views/QuizForm'))

export default [
  {
    path: '/',
    exact: true,
    component: () => (
      <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
        <Redirect to="/quiz/recent" />
      </AuthGuard>
    ),
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login')),
      },
      {
        path: '/auth/forgot-password',
        exact: true,
        component: lazy(() => import('src/views/ForgotPassword')),
      },
      {
        path: '/auth/reset/:emailAddress/:hash',
        exact: true,
        component: lazy(() => import('src/views/ResetPassword')),
      },
      {
        path: '/auth/set/:emailAddress/:hash',
        exact: true,
        component: lazy(() => import('src/views/SetPassword')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401')),
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404')),
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500')),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
  {
    path: '/quiz/create',
    component: DashboardLayout,
    exact: true,
    routes: [
      {
        path: '',
        exact: true,
        component: ({ history }) => (
          <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
            <WebSocketProvider>
              <QuizForm history={history} />
            </WebSocketProvider>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    path: '/quiz/edit/:quizId/:tab?/:view?/:viewId?/:subView?/:subViewId?',
    component: EmptyLayout,
    routes: [
      {
        path: '',
        component: ({ match: { params }, history }) => {
          if (!params.tab) {
            return <Redirect to={`/quiz/edit/${params.quizId}/questions`} />
          }
          return (
            <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
              <WebSocketProvider>
                <QuizForm collectionId={params.quizId} history={history} />
              </WebSocketProvider>
            </AuthGuard>
          )
        },
      },
    ],
  },
  {
    path: '/quiz/:filter?',
    component: DashboardLayout,
    exact: true,
    routes: [
      {
        path: '',
        exact: true,
        component: () => (
          <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
            <WebSocketProvider>
              <QuizManagementList />
            </WebSocketProvider>
          </AuthGuard>
        ),
      },
    ],
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/users',
        exact: true,
        component: () => (
          <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
            <UserManagementList />
          </AuthGuard>
        ),
      },
      {
        path: '/settings',
        exact: true,
        component: (params) => (
          <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
            <UserSettings {...params} />
          </AuthGuard>
        ),
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: (params) => (
          <AuthGuard roles={[userRoles.regular, userRoles.admin]}>
            <UserSettings {...params} />
          </AuthGuard>
        ),
      },
      {
        component: () => <Redirect to="/errors/error-404" />,
      },
    ],
  },
]
