import { login, register, invite, reqResetPassword, checkResetLink, resetPassword } from 'src/services/api'

export default function ({ session, api, users }, history, state) {
  return {
    ...session,
    login: (email, password) => {
      api.loading({ name: 'login' })
      return login(email, password)
        .then((res) => {
          if (res.error) {
            throw res.error
          }

          if (res.token) {
            session.login(res)
          }
        })
        .catch((error) => {
          api.error({ name: 'login', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'login' })
        })
    },
    reqResetPassword: (email) => {
      api.loading({ name: 'reqResetPassword' })
      return reqResetPassword(email)
        .then((res) => {
          if (!res.success) {
            throw res.error
          }
          return res
        })
        .catch((error) => {
          api.error({ name: 'reqResetPassword', error })
          return error
        })
        .finally(() => {
          api.finish({ name: 'reqResetPassword' })
        })
    },
    checkResetLink: (email, hash) => {
      api.loading({ name: 'checkResetLink' })
      return checkResetLink(email, hash)
        .then((res) => {
          if (!res.success) {
            throw res.error
          }
          return res
        })
        .catch((error) => {
          api.error({ name: 'checkResetLink', error })
          return error
        })
        .finally(() => {
          api.finish({ name: 'checkResetLink' })
        })
    },
    resetPassword: (userPassword, hash) => {
      api.loading({ name: 'resetPassword' })
      return resetPassword(userPassword, hash)
        .then((res) => {
          if (res.token) {
            session.login(res)
          } else {
            api.error({ name: 'login', error: res.error })
          }
        })
        .catch((error) => {
          api.error({ name: 'resetPassword', error })
          return error
        })
        .finally(() => {
          api.finish({ name: 'resetPassword' })
        })
    },
    register: (params) => {
      api.loading({ name: 'register' })
      const { group, ...user } = params
      return register({ user, group }, state.session.token)
        .then((res) => {
          if (!res.user && res.error) {
            throw res.error
          }

          users.addUser({ user: res.user })

          if (res.group) {
            users.addGroup({ group: res.group })
          }
        })
        .catch((error) => {
          api.error({ name: 'register', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'register' })
        })
    },
    invite: (params) => {
      api.loading({ name: 'invite' })
      const { group, ...user } = params
      return invite({ user, group }, state.session.token)
        .then((res) => {
          if (!res.user && res.error) {
            throw res.error
          }

          users.addUser({ user: res.user })

          if (res.group) {
            users.addGroup({ group: res.group })
          }
        })
        .catch((error) => {
          api.error({ name: 'invite', error })
          throw error
        })
        .finally(() => {
          api.finish({ name: 'invite' })
        })
    },
  }
}
