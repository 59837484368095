export const resultPreviewOptions = Object.freeze({
  ALL: 'All results',
  ONE: 'One result',
  MULTI: 'Multiple results',
  NONE: 'No results',
})

export const template = `
{{! SECTION:Not Found - The system didn't found any useful result }}
{{#unless returned_results}}
  <div>We could not find a recommendation at this time</div>
{{/unless}}
{{! END:NotFound }}

{{! SECTION:One Result - We just found one result}}
{{#if result}}

  {{#with result}}
  {{! HINT - "with" changes evaluation context, now "result.title" transform into "title" }}
  {{! HINT - However if you need to access variables in the previous context you have to use "../[variable_name]" }}
    {{#if title}}
      <h1>{{title}}</h1>
    {{/if}}
    {{#if description}}
      <p>{{description}}</p>
    {{/if}}
    {{#if image}}
      <img src={{image}} alt="" style="max-width: 250px;max-height: 250px;"/>
    {{/if}}
  {{/with}}

{{/if}}
{{! END:One Result }}

{{! SECTION:Many Results - we found a list of results }}
{{#if results}}

  {{#each results}}
  {{! HINT - "each" iterates over the array of results putting the current result in context (same as "with") }}

    {{#lowerThan @index 2}}
    {{! HINT - "lowerThan" and "graterThan" allow you to select ranges of results }}

      {{! HINT - "@index" is a zero based index of the current result }}
      {{#ifeq @index 0}}
        {{#if title}}
          <h1>{{title}}</h1>
        {{/if}}
        {{#if description}}
          <p>{{description}}</p>
        {{/if}}
        {{#if image}}
          <img src={{image}} alt="" style="max-width: 250px;max-height: 250px;"/>
        {{/if}}
      {{else}}
        {{#if title}}
          <h2>{{title}}</h2>
        {{/if}}
        {{#if description}}
          <p>{{description}}</p>
        {{/if}}
        {{#if image}}
          <img src={{image}} alt="" style="max-width: 200px;max-height: 200px;"/>
        {{/if}}
      {{/ifeq}}
    {{else}}
      {{#if title}}
        <h4>{{title}}</h4>
      {{/if}}
      {{#if image}}
        <img src={{image}} alt="" style="max-width: 100px;max-height: 100px;"/>
      {{/if}}
    {{/lowerThan}}
  {{/each}}

  {{/if}}
{{! END:Many Results }}
{{#if error}}
  <h1>Error: </h1>
  <p>{{error}}</p>
{{/if}}
`

export const css1 = `
/*
Sample stylesheet, feel free to make any changes.
*/

div#container {
  --main-font: 'Be Vietnam Pro', sans-serif;
  --font-family: 'Be Vietnam Pro', sans-serif;
  --primary-color: #0e1681;
  --accent-color: #020e27;
  --border-radius: 0px;
  --title-color: #020e27;
  --description-color: #020e27;
  --answer-option-bg: #0e1681;
  --answer-option-color: #fff;
  --highlight: #4754ff;
  --dark-highlight: #1B1E2A;
  --next-color: #fff;
  --next-bg: #0e1681;
  --prev-color: #1b1e2a;
  --prev-bg: #fff;
}

#title {
  color: var(--title-color);
  font-size: 2rem;
  font-family:  var(--main-font);
  font-weight: 400;
  line-height: 140%;
}

#description {
  color: var(--description-color);
  font-size: 1.25rem;
  line-height: 140%;
  font-family:  var(--main-font);
}

/* Question Answer styling */
.question {
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  font-family:  var(--main-font);
  align-items: center;
}

.question-container {
  /*color: #020e27;*/
  margin: auto;
  width: 330px;
  align-items: center;
}

.question .type-single .answer-text, .question .type-multi .answer-text {
  padding: 5px;
  line-height:1.4;
  height: 100%;
  text-align:center;
}

.question * {
  box-sizing: border-box;
}

.question .question-container.grid {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 75%;
  z-index:9;
}

.question .type-single .form-group, .question .type-multi .form-group {
  /* Todo: Remove !important when inline styles are removed */
  padding: 0 !important;
  margin: 0 !important;
  width: 330px;
  align-items: center !important;
}

/* Removes radio button */
.question .type-single .form-group input[type="radio"],
.question .type-multi .form-group input[type="checkbox"] {
  display: none;
  opacity: 0;
}

.question .type-single .form-group label, .question .type-multi .form-group label {
  display: block;
  position: relative;
  width: 100%;
  height: 60px;
  margin: 0;
  margin-bottom: 12px;
  /*padding: 8px;*/
  /*margin: 4px 4px 4px 4px;*/
  border-radius: 0px;
  line-height:140%;
  font-size: 16px;
  font-weight: 700;
  background: var(--answer-option-bg);
  /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.15); */
  cursor: pointer;
  border: var(--answer-option-bg) solid 1px;
  opacity:0.95;
}

.question .type-single .answer-text {
  display: flex !important;
  width: 100%;
  min-width: 120px;
  flex-direction: row;
  align-items: center!important;
  /* attempting to center */
  justify-content: center!important;
  border-radius: 0px;
  line-height: 140%;
  color: #FFF;
  border: 2px solid transparent;
  padding: 7px;
}

.question .type-multi .answer-text {
  display: flex !important;
  width: 100%;
  min-width: 120px;
  flex-direction: row;
  align-items: center!important;
  justify-content: center!important;
  border-radius: 0px;
  line-height: 140%;
  color: var(--answer-option-bg);
  border: 2px solid transparent;
  background-color: #fff;
  padding: 7px;
}

.question .type-single input:checked ~ .answer-text, .question .type-multi input:checked ~ .answer-text {
  font-weight: 600;
  border: 0px solid #0e1681;
  color: #fff;
  background-color: #4754ff;
}

.question .type-single input:hover ~ .answer-text, .question .type-multi input:hover ~ .answer-text {
  font-weight: 600;
  border: 0px solid #0e1681;
  color: #fff;
  background-color: #4754ff;
  transform: scale(1.02);
}

.question .type-single .answer-text::before, .question .type-multi .answer-text::before,
.question .type-multi input:checked ~ .answer-text::after {
  display: none;
  position: absolute;
  content: "";
  top: 50%;
}

.question .type-multi .answer-text::before {
  width: 20px;
  height: 20px;
  margin-top: -13px;
  border-radius: 3px;
}

.question .type-single input:checked ~ .answer-text::before {
  box-shadow: inset 0px 0px 0px 4px white;
  background: var(--answer-option-bg);
  border-color: var(--answer-option-bg);
  color:#fff;
}

.question .type-multi input:checked ~ .answer-text::before {
  background: #0e1681;
  border-color: transparent;
}

.question .type-multi input:checked ~ .answer-text::after {
  left: 23px;
  width: 6px;
  height: 13px;
  margin-top: -11px;
  border: solid white;
  border-width: 0 4px 4px 0;
  transform: rotate(40deg);
}

/* Text input styling */
.type-text {
  align-content:center;  
}

.question .type-text textarea,
.question .type-text input, 
.question .type-text select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: inherit;
    font-family:  var(--main-font);
    font-size: 20px;
    font-weight: 700;
    padding: 10px 20px;
    max-width: 100%;
    color: #64748b;
    /* Todo: Remove !important when inline styles are removed */
    border: 2px solid var(--answer-option-bg)!important;
    margin-bottom: 12px;
    text-align: center;
    width: 330px;
    height: 60px;
}

.question textarea:hover,
.question textarea:focus,
.question textarea:active {
    outline: none;
}

/* remove stepper on number text fields */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.answer-text:active {
  background-color: #0e1681;
  outline: #0e1681;
  stroke-width: 2px;
}

.answer-text:hover {
  background-color: #4754ff;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #00BF80; /* Blue */
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

/* SLIDER STYLE */
#question > div.grid.question-container.slider {
  align-items: unset;
}

input[type=range] {
  height: 48px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 330px;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: var(--answer-option-bg);
  border-radius: 25px;
  border: 0px solid #010101;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 3px var(--dark-highlight);
  border: 1px solid var(--dark-highlight);
  height: 30px;
  width: 30px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.5px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: var(--answer-option-bg);
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 0px 0px 0px #000000;
  background: #3FC1C9;
  border-radius: 25px;
  border: 0px solid #010101;
}
input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 3px var(--dark-highlight);
  border: 1px solid var(--dark-highlight);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 20px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: var(--answer-option-bg);
  border: 0px solid #010101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-fill-upper {
  background: var(--answer-option-bg);
  border: 0px solid #010101;
  border-radius: 50px;
  box-shadow: 0px 0px 0px #000000;
}
input[type=range]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 1px 1px 3px var(--dark-highlight);
  border: 1px solid var(--dark-highlight);
  height: 40px;
  width: 40px;
  border-radius: 50px;
  background: #FFFFFF;
  cursor: pointer;
}
input[type=range]:focus::-ms-fill-lower {
  background: var(--answer-option-bg);
}
input[type=range]:focus::-ms-fill-upper {
  background: var(--answer-option-bg);
}

label#value {
  font-weight:700;
  font-size:1.25rem;
}

/* Question Action Button Styling */

.question-actions, .interstitial-actions {
  margin: 40px auto 40px auto;
  display: flex;
  width: 330px;
  align-items: end;
  justify-content: space-between;
  flex-direction: row;
  z-index:9;
}

.question-actions > button, .interstitial-actions > button {
  /*box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);*/
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  font-family:  var(--main-font);
  font-weight: bold;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-top: 0px;
}

.question-actions.type-single {
  margin-top:40px!important;
}

#next, #next a {
  background-color: var(--next-bg);
  color: var(--next-color);
  text-decoration: none;
  width: 60%;
  height: 45px;
  z-index:9;
}

#next:disabled {
  opacity: 0.5;
}

#prev {
  background-color: var(--prev-bg);
  color: var(--prev-color);
  height:45px;
  border-radius: 0px;
  z-index:9;
}

.quiz-results-container {
  color:#fff;
}


@media only screen and (max-width: 960px) {
    div#question {
    width:90%;
  }
}

@media only screen and (max-width: 768px) {

  .question .type-text textarea, .question .type-text input, .question .type-text select {
    width:90%;
    text-align:left;
  }
  .answer-text {
    width:90%;
  }
  
  .form-group {
    width:90%!important;
    text-align:center;
  }
  
  .question .question-container.grid {
    width:90%!important;
  }
  
  .question-actions {
    width:90%!important;
  }
}
`

export const css2 = `@import url("https://fonts.googleapis.com/css2?family=Inter");

/* For ease of customization, we use CSS variables to define the theme colors and font. */
/* You can change these variables to match your brand colors and font. */
#container {
  --font-family: "Inter", sans-serif; /* Default font */
  --primary-color: #61BD70; /* Primary color: used for buttons, borders, outlines */
  --accent-color: #183A1E; /* Accent color: used for text, links, and other accents */
  --hover-color: #F1F9F2; /* Hover color: used for answer options */
  --transition-background: #F1F9F2; /* Background color for transition questions */
  --border-radius: 12px; /* Border radius for buttons, inputs, and other elements */
}

#container {
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%; /* Full height of the viewport */
  margin: 0 auto; /* Centering horizontally if needed */
}

#title {
  color: var(--accent-color);
  font-family: var(--font-family);
  font-weight: 600;
  font-style: normal;
  font-size: 32px;
  line-height: 40.5px;
  margin-bottom: 60px;
}

#description {
  color: var(--accent-color);
  font-family: var(--font-family);
  font-weight: 600;
  font-style: normal;
  line-height: 33px;
  font-size: 20px;
}

/* Question Answer styling */
.question {
  max-width: 660px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: auto;
  margin-right: auto;
  font-family: var(--font-family);
  align-items: center;
}

.question * {
  box-sizing: border-box;
}

.question-container {
  color: var(--accent-color);
  margin: auto;
  align-items: center;
}

.question .question-container.grid {
  margin-top: 24px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.question .type-single .answer-text,
.question .type-multi .answer-text {
  padding: 5px;
  line-height: 1.4;
  height: 100%;
}

.question .type-single .form-group,
.question .type-multi .form-group {
  padding: 0;
  margin: 0;
  width: 100%;
  align-items: center;
}

/* Removes radio button */
.question .type-single .form-group input[type="radio"],
.question .type-multi .form-group input[type="checkbox"] {
  display: none;
  opacity: 0;
}

.question .type-single .form-group label,
.question .type-multi .form-group label {
  display: block;
  position: relative;
  width: 100%;
  min-height: 60px;
  margin: 0 0 12px;
  line-height: 20px;
  font-size: 18px;
  font-weight: 400;
  background-color: white;
  cursor: pointer;
  border: 1px solid var(--primary-color);
  border-radius: 12px;
}

.question .type-single .answer-text,
.question .type-multi .answer-text {
  display: flex;
  width: 100%;
  min-width: 120px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  padding: 7px 7px 7px 21px;
  min-height: 60px;
}

.question .type-single input:checked ~ .answer-text,
.question .type-multi input:checked ~ .answer-text {
  border-radius: 12px;
  font-weight: 600;
  background-color: var(--hover-color);
  border: 1px solid var(--hover-color);
}

.question .type-single label:hover,
.question .type-multi label:hover {
  background-color: var(--hover-color);
  border-radius: 12px;
}

/* Text input styling */
.question .type-text textarea,
.question .type-text input,
.question .type-text select, 
.question .type-tag .input-and-button-wrapper,
.question .type-multi-dropdown .input-and-buttons-wrapper
{
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-family: var(--font-family);
  font-size: 20px;
  color: #202324;
  line-height: 22px;
  font-weight: 400;
  padding: 10px 20px;
  max-width: 100%;
  border: 1px solid var(--accent-color);
  border-radius: 12px;

  margin-bottom: 12px;
  width: 100%;
  height: 62px;
}


.question .type-tag .input-and-button-wrapper {
  max-width: 660px!important;
}

.question .type-tag {
  width: 100%;
}

.tag-input {
  display:flex;
  font-size: 16px!important;
}

.input-wrapper {
  width: 100%!important;
}

.tag-wrapper {
  font-size: 14px!important;
  border-radius: 4px!important;
  background-color: var(--primary-color)!important;
  place-items: center;
  height: 32px;
  padding: 5px!important;
}

/* Date picker - calendar */
.datepicker-box {
  border-radius: 12px;
  border: 1px solid var(--primary-color)!important;
  padding: 24px;
}

.calendar-cell {
  flex: 1 0 13%!important;
}

.calendar-cell .calendar-highlight .calendar-dimmed {
  height: 28px!important;
}

.calendar-cell:hover {
  color: #FFF!important;
  background: var(--primary-color)!important;
}

.datepicker-month-prev,
.datepicker-month-next {
  border: none;
  font-weight: 800;
  color: var(--primary-color);
  border-radius: 4px;
  padding: 7px 10px;
}

.calendar-selected {
  background:var(--primary-color)!important;
  color: #FFF!important;
  opacity: 0.6!important;
}
.input.autocomplete-input{
  border-radius: 4px;
  border: 1px solid var(--primary-color)!important;
  padding: 7px 10px;
}

.calendar-cell {
  height: 28px!important;
  width: 20px!important;
}
/* Slider Styling */
.slider {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 660px; /* Align with other content max-width */
  margin: 20px auto; /* Center slider and provide some spacing */
}

.slider input[type="range"] {
  -webkit-appearance: none; /* Override default appearance */
  width: 100%;
  height: 8px;
  background: #E6E6E6; /* Light grey background */
  outline: none;
  opacity: 0.7;
  -webkit-transition: .2s;
  transition: opacity .2s;
  border-radius: 5px; /* Rounded corners for the slider */
}

.slider input[type="range"]:hover {
  opacity: 1; /* Fully opaque on hover */
}

.slider input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; 
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--primary-color); 
  cursor: pointer; 
  border-radius: 50%; 
}

.slider input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--primary-color);
  cursor: pointer;
  border-radius: 50%;
}

/* Slider Label Styling */
.label {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-family);
  color: var(--accent-color); /* Theme color for text */
  margin-top: 8px; /* Space between slider and labels */
  width: 100%;
}

#value {
  font-size: 18px; /* Larger font for current value */
  font-weight: 600; /* Make it bold */
  margin-bottom: 12px; /* Space above the slider */
  text-align: center; /* Center the label */
  width: 100%;
}

.min, .max {
  font-size: 16px; /* Slightly smaller font for min/max labels */
}


.question .type-text input[type="email"] {
  padding-left: 50px;
  background-repeat: no-repeat;
  background-position: 20px;
  background-size: 20px;
  background-clip: border-box;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAgCAYAAADnnNMGAAACHElEQVRIie2WsW4TQRBA3+zZMaWhonQ6SpBAQVQmP5D8QRKU4oLsZOU0UCUpkSLbMVZkJEScPyA/ENxFllKYL4j5AkyBBA7eobAs+e58+CwcKUJMOTszb3ZnZneFKeJbm8fJqiArQA5AkA6qHXftDhqNandaDIlbsNZm+2oqqqz/KYBBTtJmUKpWq72ZIL61OXHm0yjzBHKlfbcctyszkTwwlRkAAIuS9j7ELXphhV8srYvwKqxX1RbCGdAW5D6QDWZG7snSs+5l++Jz2DcVVgiyBhoEwEHjbWV/TGX97dK+wF7AVVkDTqMxxz2tzf505mvAQGgeH5U3wo6AbBV3zxHNj+eTMe5euAkCNflB6mE40sDoxwkAAFUntbDy+4RaTix8IN1r71vcmsm4SNt6v1LZiN00yDzk34EIDCdcnbciuEfDFh4zEJpO+TLZXXOx9n3XHN0AslXYXcXoCeHh+ntRdWa5UT9sGQx7NwAAEPG0DGBAI7MxN9Fh7NvYXdpBpTUrJHJBxkhX++75qFs2C4XFtFk4J+FzkGgn6szG+IP0vl6/EniRMMFkkEb9MHJEx7Vy4mNLBPF9GzmWzUIh8cuZCCIZsxNWpb2F7aSQZIVX7MtiKauenAEwcDso+SleM0IAFdZxOvweSexPaqLcxmH8D0Fj/7BzkB6AUeTopghO3CmAd9m+aD1eenpXkAfAnTnF7ym8eVervAb4DUUGsbFjblsxAAAAAElFTkSuQmCC");
}

.question textarea:hover,
.question input:hover,
.question textarea:focus,
.question textarea:active,
.question input:focus,
.question input:active {
  outline: none;
}

.question textarea:hover,
.question input:hover,
.question textarea:focus,
.question textarea:active,
.question input:focus,
.question input:active {
  outline: #0e1681;
  stroke-width: 1px;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #00bf80;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  margin: auto;
}

/* Question Action Button Styling */
.question .question-skip #skip {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: var(--accent-color);
  margin-bottom: 12px;
  margin-top: 12px;
}
.question div.question-skip {
  margin-bottom: 12px;
  margin-top: 12px;
}

.question-actions > button {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border: none;
  font-family: var(--font-family);
  font-weight: bold;
  border-radius: 0px;
  padding: 10px 20px;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  margin-top: 0px;
}
.question-actions {
  display: flex;
  left: 0;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
}

.question:after {
  display: block;
  width: 100%;
  height: 250px;
  content: "";
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center; /* Center the background image */
  margin-top: 108px;
}

/* Hide footer on interstitials */
.question:has(.type-transition):after {
  display: none;
}

/* Transition design */
#container:has(.type-transition) {
  background-color: var(--transition-background);
}

.question-actions.type-transition > #prev {
  background-color: var(--transition-background);
}

/*.question-actions {*/
/*    position: sticky;*/
/*    background-color: white;*/
/*    width: 100VW;*/
/*    margin-left: -16px;*/
/*}*/

#next,
#next a {
  background-color: var(--primary-color);
  color: #fff;
  text-decoration: none;
  border-radius: 12px;
  width: 60%;
  height: 54px;
  margin-left: auto;
  flex-grow: 1;
  max-width: 350px;
}

#next:disabled {
  opacity: 0.5;
}

#prev {
  min-width: 100px;
  color: var(--primary-color);
  background-color: #fff;
  height: 54px;
  border: 0px;
  margin-right: 35px;
  border-radius: 8px;
  font-weight: 500;
}

#points {
  display: none;
}

.quiz-results-container {
  color: #fff;
}

.question
  .grid.question-container
  .type-text-dropdown
  .type-text-dropdown-open
  button::after {
  content: "";
  width: 18px;
  height: 18px;
  transform: translate(-150%, -200%);
  background-repeat: no-repeat;
  background-size: contain;
}
.question
  .grid.question-container
  .type-text-dropdown
  .type-text-dropdown-clear
  button {
  transform: translate(-80%, -240%) scale(2);
}
.question .type-text-dropdown {
  width: 100%;
}

.type-text-dropdown-options {
  margin-top: -11px;
  border-radius: 4px!important;
}

.type-text-dropdown-option {
  font-size: 18px!important;
}
@media only screen and (max-width: 768px) {
  #title {
    /*font-size: 18px;
    line-height: 24px;*/
  }
  #description {
    /*font-size: 14px;
    line-height: 24px;*/
  }
  .question .type-single .form-group input[type="radio"],
  .question .type-multi .form-group input[type="checkbox"] {
    display: none;
    opacity: 0;
  }
  .question-container {
    padding-left: 12px;
    padding-right: 12px;
  }

  .question .type-single .form-group label,
  .question .type-multi .form-group label {
    font-size: 16px;
    line-height: 18px;
    min-height: 60px;
  }
  
  .question .type-text textarea,
  .question .type-text input,
  .question .type-text select {
    text-align: left;
  }

  .question .type-single .answer-text,
  .question .type-multi .answer-text {
    min-height: 60px;
  }

  .answer-text {
    width: 90%;
  }

  .form-group {
    width: 90%;
  }

  .question .question-container.grid {
    width: 100%;
    margin-bottom: 10px;
  }
  
  .question-actions {
    
    flex-direction: column-reverse; /* Stack children vertically */
    align-items: center; /* Center-align the buttons */
  }

  #next, #prev {
    width: 95%; /* Adjust the width as needed */
    margin: 10px 0; /* Add some margin for spacing */
    flex-grow: 0; /* Override any existing flex-grow */
    max-width: 100%;
    
  }

}

@media only screen and (max-width: 425px) {
  #title {
    font-size: 1.5rem;
  }
}
`
